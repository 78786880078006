<template>
      <article class="entry">
              <div v-show='editPost' class='icons'>
              <div @click='editBlog' class='icon'>
              <Edit class='edit' />
              </div>
              <br>
              <div @click='deletePost' class='icon'>
              <Delete class='delete' />
              </div>
              <br><br>
              </div>
              <div class="entry-img">
                <img v-bind:src="post.blogCoverPhoto" alt="" class="img-fluid center" style="max-width:400px; height:300px; width:100%;">
              </div>

              <h2 class="entry-title">
                <router-link class='a' :to="{ name: 'ViewBlog', params: { blogid: this.post.blogID } }">{{post.blogTitle}}</router-link>
              </h2>

              <div class="entry-meta">
                <ul>
                  <li class="d-flex align-items-center"><i class="bi bi-person"></i> TecnoBlog</li>
                  <li class="d-flex align-items-center"><i class="bi bi-clock"></i> <time datetime="2020-01-01">{{ new Date(post.blogDate).toLocaleString('en-us', { dateStyle: 'long' }) }}</time></li>
                </ul>
              </div>

              <div class="entry-content">
                <p>
                  {{post.blogTags}}
                </p>
                <div class="read-more">
                  <router-link class='a' :to="{ name: 'ViewBlog', params: { blogid: this.post.blogID } }">
                    Leer <i class="bi bi-arrow-right"></i> </router-link>
                </div>
              </div>

            </article>
</template>

<script>

import Edit from '../../assets/icons/edit-regular.svg'
import Delete from '../../assets/icons/trash-regular.svg'

export default {
  name: 'BlogPost',
  props: ['post'],
  components: {
    Edit,
    Delete
  },
  methods: {
    deletePost () {
      this.$store.dispatch('deletePost', this.post.blogID)
    },
    editBlog () {
      this.$router.push({ name: 'EditBlog', params: { blogid: this.post.blogID } })
    }
  },
  computed: {
    editPost () {
      return this.$store.state.editPost
    }
  }
}
</script>

<style>

svg {
    max-width: 4%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

</style>
