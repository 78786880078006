<template>
  <div class="Blog">
      <br>
      <main id="main">
    <section class="breadcrumbs">
      <div class="container">
        <ol>
          <li><router-link :to="{ name: 'Home', params: { userId: 123 }}" class="a" v-scroll-to="'#'">Home</router-link>
          <li>.</li>
        </ol>
        <h2 style="text-align:left">Blog</h2>
      </div>
    </section>
    <!-- ======= Blog Section ======= -->
    <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">
        <div v-if='admin' class='toggle-edit'>
        <span>Editar Posts --> </span>
        <input type='checkbox' v-model='editPost' />
        </div>

        <div class="row">
          <div class="col-lg-8 entries">

                    <BlogPost :post='post' v-for='(post, index) in blogPosts' :key='index' />

            <div style="display:none" class="blog-pagination">
              <ul class="justify-content-center">
                <li><a href="#">1</a></li>
                <li class="active"><a href="#">2</a></li>
                <li><a href="#">3</a></li>
              </ul>
            </div>

          </div><!-- End blog entries list -->

          <div class="col-lg-4">

            <div class="sidebar">

              <h3 style="display:none" class="sidebar-title">Buscar</h3>
              <div style="display:none" class="sidebar-item search-form">
                <form action="">
                  <input type="text">
                  <button type="submit"><i class="bi bi-search"></i></button>
                </form>
              </div><!-- End sidebar search formn-->

              <h3 class="sidebar-title">Tags</h3>
              <div class="sidebar-item tags">
                <ul>
                  <li><a href="#">App</a></li>
                  <li><a href="#">IT</a></li>
                  <li><a href="#">Business</a></li>
                  <li><a href="#">Ciberseguridad</a></li>
                  <li><a href="#">Vulnerabilidades</a></li>
                  <li><a href="#">Disaster Recovery</a></li>
                </ul>
              </div><!-- End sidebar tags-->

              <h3 style="display:none" class="sidebar-title">Recent Posts</h3>
              <div style="display:none" class="sidebar-item recent-posts">
                <div class="post-item clearfix">
                  <img src="assets/img/blog/blog-recent-1.jpg" alt="">
                  <h4><a href="#">Nihil blanditiis at in nihil autem</a></h4>
                  <time datetime="2020-01-01">Jan 1, 2020</time>
                </div>

                <div class="post-item clearfix">
                  <img src="assets/img/blog/blog-recent-2.jpg" alt="">
                  <h4><a href="#">Quidem autem et impedit</a></h4>
                  <time datetime="2020-01-01">Jan 1, 2020</time>
                </div>

                <div class="post-item clearfix">
                  <img src="assets/img/blog/blog-recent-3.jpg" alt="">
                  <h4><a href="#">Id quia et et ut maxime similique occaecati ut</a></h4>
                  <time datetime="2020-01-01">Jan 1, 2020</time>
                </div>

              </div><!-- End sidebar recent posts-->

              <div class="sidebar-item tags">
              <br>
              <img src="assets/img/favicon.webp" style="max-height: 100px;" class="center" alt="">
              </div>
            </div><!-- End sidebar -->

          </div><!-- End blog sidebar -->

        </div>

      </div>
    </section><!-- End Blog Section -->
    </main><!-- End #main -->
  </div>
</template>

<script>
import BlogPost from './Blog/BlogPost.vue'
export default {
  name: 'Blog',
  components: {
    BlogPost
  },
  computed: {
    blogPosts () {
      return this.$store.state.blogPosts
    },
    editPost: {
      get () {
        return this.$store.state.editPost
      },
      set (payload) {
        this.$store.commit('toggleEditPost', payload)
      }
    },
    admin () {
      return this.$store.state.profileAdmin
    }
  },
  beforeDestroy () {
    this.$store.commit('toggleEditPost', false)
  },
  data () {
    return {
    }
  }
}
</script>

<style>
.blog {
    text-align: left;
}

body {
    font-family: "Open Sans", sans-serif;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
